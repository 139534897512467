import { IntroSection } from "./Components/Introduction/IntroSection";
import Hero from "./Components/Hero/Hero";
import styled from "styled-components";
import backjpic from "./images/krit.jpg";
import captial from "./images/greycapital.jpg";
import clocktower from "./images/Clocktower.jpg";
import strings from "./strings";
import { AboutSection } from "./Components/About/AboutSection";
import { SectionDividerWithImage } from "./Components/SectionDivider/SectionDividerWithImage";
import { NewsSection } from "./Components/News/NewsSection";
import { ServicesSection } from "./Components/Services/ServicesContent";
import { ContactSection } from "./Components/Contact/ContactSection";
import { FloatingNav } from "./Components/FloatingNav/FloatingNav";

const AppContainer = styled.div`
  display: grid;
  line-height: 1.6;
  grid-row-gap: 2em;
  h2,
  h3,
  p {
    margin: 1em;
    max-width: 65ch;
    font-size: large;
  }

  .fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`;

const StyledHR = styled.hr`
  width: 30%;
  margin-top: 4em;
`;

const AppContentContainer = styled.div`
  display: grid;
`;

const App = () => {
  return (
    <AppContainer>
      <Hero />
      <AppContentContainer>
        <AboutSection />
        <SectionDividerWithImage image={backjpic} />
        <IntroSection />
        <SectionDividerWithImage image={captial} />
        <NewsSection />
        <StyledHR />
        <ServicesSection />
        <SectionDividerWithImage image={clocktower} />
        <ContactSection />
      </AppContentContainer>
      <hr />
      <FloatingNav />
    </AppContainer>
  );
};

export default App;
