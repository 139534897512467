import styled from "styled-components";

import skyline from "../../images/skyline.jpeg";

const HeroContainer = styled.div`
  display: grid;
  justify-content: center;
  height: 100vh;
  grid-template-rows: 2 auto;
  position: relative;
  z-index: 1;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)),
    url(${skyline});
  background-size: cover;
  color: white;
`;
const ContentContainer = styled.div`
  display: grid;
  align-content: center;
  z-index: 1;
  position: relative;
  margin: 10px;
  h1 {
    justify-self: center;
    font-size: clamp(1em, 1em + 5vw, 4em);
    font-weight: 400;
    margin: 0;
    line-height: 39.12px;
    font-family: "Aldrich", sans-serif;
  }
  h4 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: clamp(0.5rem, 0.5 + 10vw, 1rem);
  }
`;
const Hero = () => {
  return (
    <HeroContainer>
      <ContentContainer>
        <h1> Cornelius Investigations</h1>
        <h4>Private Investigator with over 25 years in Law Enforcement</h4>
      </ContentContainer>
    </HeroContainer>
  );
};

export default Hero;
