import "../styles/hero.scss";

import styled from "styled-components";
import React, { useEffect, useState } from "react";

const ContentContainer = styled.div`
  background-color: white;
  justify-items: center;

  background: linear-gradient(to top, rgb(0 0 0 / 68%) 20%, rgba(0, 0, 0, 1))
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  display: grid;
  grid-gap:2em;
  align-items: center;
  color: black;
  h1{
    font-weight:200;
  }
  padding: 4em 2px;
`;

interface HeroProps {
  label: string;
  content?: any;
}
const ContentSection: React.FC<HeroProps> = ({ label, content }) => {
  const domRef = React.useRef<HTMLDivElement>(null);
  const [isVisable, setIsVisable] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!isVisable) setIsVisable(entry.isIntersecting);
      });
    });
    const { current } = domRef;
    if (current) observer.observe(current);
    if (current) return () => observer.unobserve(current);
  });
  return (
    <ContentContainer>
      <h1 ref={domRef}>{label.toUpperCase()}</h1>
      {content}
    </ContentContainer>
  );
};

export default ContentSection;
