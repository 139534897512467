import { FormLabel, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

export const EmailField = () => {
  const { values, handleChange } = useFormikContext<{ email: string }>();

  const validateEmail = (email: string) => {
    if (!email.includes("@") || !email.includes(".") || email.length < 5)
      return "Enter a valid email";
  };
  return (
    <>
      <FormLabel htmlFor="email">Email</FormLabel>
      <TextField
        name="email"
        type="email"
        variant="filled"
        value={values.email}
        onChange={handleChange}
      />
    </>
  );
};
