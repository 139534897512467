import styled from "styled-components";
import ContentSection from "../SectionContent";
import Icon from "./Icon";
import chess from "../../images/icons/Services/chess.png";
import civil from "../../images/icons/Services/civil.png";
import fake from "../../images/icons/Services/fake.png";
import find from "../../images/icons/Services/find.png";
import insurance from "../../images/icons/Services/insurance.png";
import binoculars from "../../images/icons/Services/binoculars.png";

interface Props {
  direction: "column" | "row";
}
const ServiceContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 2em;
  align-items: baseline;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
`;
const StyledServices = styled.div`
  padding: 2em;
  background-color: #5e5e5e94;
  padding: 4em;
  color: white;
  border-radius: 60px;
  img {
    width: clamp(1em, 1em + 10vh, 5em);
  }
  box-shadow: 15px 20px 20px grey;
  max-width: clamp(6em, 6em + 40vw, 43em);
`;
const Services = () => {
  return (
    <StyledServices>
      <ServiceContainer direction="row">
        <Icon pic={chess} text="Corporate Investigations" />
        <Icon pic={civil} text="Criminal, Civil, and Domestic Investigations" />
        <Icon pic={fake} text="Fraud Investigations" />
        <Icon pic={find} text="Background Check" />
        <Icon pic={insurance} text="Personal Injury and Insurance Claims" />
        <Icon pic={binoculars} text="Surveillance" />
      </ServiceContainer>
    </StyledServices>
  );
};
export const ServicesSection = () => {
  return <ContentSection label={"Services"} content={<Services />} />;
};
