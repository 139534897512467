import { FormLabel, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

export const NameField = () => {
  const { values, handleChange } = useFormikContext<{ name: string }>();

  const validateName = (name: string) => {
    if (name.length) return;
    return "Enter a name";
  };
  return (
    <>
      <FormLabel htmlFor="name">Name</FormLabel>
      <TextField
        name="name"
        id="name"
        variant="filled"
        value={values.name}
        onChange={handleChange}
      />
    </>
  );
};
