import { FormLabel, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

export const MessageField = () => {
  const { values, handleChange } = useFormikContext<{ message: string }>();

  const validateMessage = (message: string) => {
    if (!message.length) return;
    return "Enter a valid message";
  };
  return (
    <>
      <FormLabel htmlFor="message">Message</FormLabel>

      <TextField
        name="message"
        id="message"
        type="message"
        placeholder="How can I help?"
        variant="filled"
        multiline
        rows={4}
        value={values.message}
        onChange={handleChange}
      />
    </>
  );
};
