import strings from "../../strings";
import ContentSection from "../SectionContent";

const { about } = strings;
const { aboutSection, introSection } = about;
const { description, closing } = aboutSection;
const { guaranteeHeader, guarantees } = introSection;

const IntroContent = () => {
  return (
    <div>
      <div>
        <p>{description}</p>
        <p>
          {guaranteeHeader}
          <ul>
            {guarantees.map((guarantee) => {
              return <li>{guarantee}</li>;
            })}
          </ul>
        </p>
        <p>{closing}</p>
      </div>
    </div>
  );
};

export const IntroSection = () => {
  return (
    <>
      <ContentSection label={"Hi, Im James"} content={<IntroContent />} />
    </>
  );
};
