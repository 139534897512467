import { Button, FormLabel, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 50%;
  justify-self: center;
  align-self: center;
`;
export const SubmitButton = () => {
  return (
    <>
      <StyledButton
        variant="contained"
        style={{ marginBottom: "20px", padding: "1em" }}
        type="submit"
      >
        SUBMIT
      </StyledButton>
    </>
  );
};
