import React, { useState } from "react";
import styled from "styled-components";
import ContentSection from "../SectionContent";

const News = () => {
  const StyledNews = styled.div`
    justify-self: center;
    #frameDiv {
      display: flex;
      aspect-ratio: 16/9;
      width: clamp(5em, 5em + 50vw, 90em);
      justify-self: center;
      align-items: center;
      background-color: white;
      flex-flow: column;
      grid-row-gap: 3em;
    }
    iframe {
      flex: 1;
      max-width: 40em;
      aspect-ratio: 16/9;
      border: none;
      margin: 0;
      padding: 0;
    }
  `;

  const YoutubeComponent = () => {
    return (
      <iframe
        src={url}
        frameBorder="0"
        className="yt_player_iframe"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    );
  };

  const domRef = React.useRef<HTMLDivElement>(null);
  const [isVisable, setIsVisable] = useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!isVisable) setIsVisable(entry.isIntersecting);
      });
    });
    const { current } = domRef;
    if (current) observer.observe(current);
    if (current) return () => observer.unobserve(current);
  });

  const url = isVisable
    ? "https://www.youtube.com/embed/2m1xh0QyV9A?rel=0&autoplay=1&mute=1"
    : "https://www.youtube.com/embed/2m1xh0QyV9A?rel=0&mute=1";
  return (
    <StyledNews>
      <div id="frameDiv" ref={domRef}>
        <YoutubeComponent />
      </div>
    </StyledNews>
  );
};

export const NewsSection = () => {
  return (
    <>
      <ContentSection label={"In the News"} />
      <News />
    </>
  );
};
