import strings from "../../strings";
import ContentSection from "../SectionContent";
const { about } = strings;
const { introSection, aboutSection } = about;
const { experinceHeading, experinces } = aboutSection;
const { companyName, info, mission } = introSection;
const AboutContent = () => {
  return (
    <div>
      <div>
        <p>
          <strong>{companyName} </strong> {info}
        </p>
      </div>
      <div>
        <p>{mission}</p>
        <p>
          {experinceHeading}

          <ul>
            {experinces.map((experince) => {
              return <li>{experince}</li>;
            })}
          </ul>
        </p>
      </div>
    </div>
  );
};

export const AboutSection = () => {
  return <ContentSection label={"About"} content={<AboutContent />} />;
};
