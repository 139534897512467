import styled from "styled-components";

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
`;
const StyledLabel = styled.p`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  margin: 1em;
  max-width: 17ch !important;
  text-align: center;
  font-size: large;
  margin-top: 0 !important;
`;
const Icon = ({ pic, text }: { pic: string; text: string }) => {
  return (
    <FlexDiv>
      <div>
        <img src={pic} />
      </div>
      <StyledLabel>{text}</StyledLabel>
    </FlexDiv>
  );
};

export default Icon;
