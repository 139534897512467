import styled from "styled-components";

export const SectionDividerWithImage = styled.div<{ image: string }>`
  background-color: white;
  justify-items: center;
  padding: 2em;
  background: linear-gradient(
      to bottom,
      rgb(50 50 80 / 80%),
      rgb(50 50 80 / 80%)
    ),
    url(${(props) => props.image});
  background-attachment: fixed;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: grid;
  align-items: center;
  backdrop-filter: blur(2px);
  height: 25em;
  // background: linear-gradient(to top, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)),
  //   url(${(props) => props.image});
`;
