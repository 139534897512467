const strings = {
  about: {
    introSection: {
      companyName: "James Cornelius Investigations, LLC",
      info: "is a private investigation agency based in Jackson, MS and surrounding areas directed by a former  law enforcement officer with over 25 years of investigations education, training, and experience. Our clients include private individuals, law firms, government agencies, and corporate executives.",
      mission:
        " Our mission is to provide specialized high-quality investigative services to our clients, focused on giving you the best possible results.",
      guaranteeHeader: "I guarantee that I will always:",
      guarantees: [
        "Provide discretion and strict confidentiality",
        "Work with honesty, integrity, and respect for our clients",
        "Obtain information and evidence legally and ethically",
        "Use the latest in technology to assist our clients",
        "Provide well-written, detailed, and timely reports",
      ],
    },
    aboutSection: {
      heading: "Hi, Im James",
      description:
        "I’m a former law enforcement officer with over 25 years of investigative training, education, and experience. I began my career as a Law Enforcement Officer transitioning over to a homicide detective for the City of Jackson. My extensive education, training, and experience makes me a highly qualified private investigator, and I am here to assist you.",
      experinceHeading:
        " We have extensive training and experience in the following areas:",
      experinces: [
        "Background Investigations",
        "Fraud Investigations",
        "Criminal, Civil, and Domestic Cases",
        "Corporate Investigations",
        "Surveillance",
        "Legal Research",
      ],
      closing:
        "Jackson, MS is my home, and I am dedicated to serving and improving lives in my community. All investigations are always confidential and discreet.",
    },
  },
};

export default strings;
