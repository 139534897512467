import { FormLabel, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

export const NumberField = () => {
  const { values, handleChange } = useFormikContext<{ phone: string }>();

  const validatePhoneNumber = (number: string) => {
    if (number.length < 10) return;
    return "Enter a valid phone number";
  };
  return (
    <>
      <FormLabel htmlFor="pnumber">Phone Number</FormLabel>
      <TextField
        name="phone"
        id="pnumber"
        variant="filled"
        value={values.phone}
        onChange={handleChange}
      />
    </>
  );
};
