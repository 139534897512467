import styled from "styled-components";

import lIIcon from "../../images/icons/NavIcons/LinkedIn_icon.svg";

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  bottom: 0;
  margin-bottom: 20px;
  padding: 5px;
  position: fixed;
  img {
    width: 2em;
  }
`;
export const FloatingNav = () => {
  return (
    <NavContainer>
      <a href="https://www.linkedin.com/in/james-cornelius-b6436b101/">
        <img src={lIIcon} />
      </a>
    </NavContainer>
  );
};
