import { FormControl } from "@mui/material";
import { Form, Formik } from "formik";
import { send } from "@emailjs/browser";
import styled from "styled-components";
import ContentSection from "../SectionContent";
import { EmailField } from "./EmailField";
import { NumberField } from "./NumberField";
import { NameField } from "./NameField";
import { MessageField } from "./MessageField";
import { SubmitButton } from "./SubmitButton";
import { formType } from "../../types/types";

const serviceID = "service_10nht5u";
const templateID = "template_3jvm2gn";
const publicKey = "V-44UtZ5iHsj5IfWa";

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled(FormControl)`
  background-color: white;
  padding: 2em;
  row-gap: 19px;
  max-width: 450px;
  width: clamp(200px, 200px + 20vw, 450px);
  border: solid black 1px;
  box-shadow: 11px 22px 10px 0px rgba(0, 0, 0, 0.26);
`;

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const handleClick = async ({
  name,
  email,
  phone,
  message,
}: formType): Promise<void> => {
  const templateParams = {
    customer_name: name,
    customer_email: email,
    service_name: "Cornelius Investigations",
    customer_message: message,
    customer_phone: phone,
  };

  sendConfirmation(templateParams);
};

const sendConfirmation = (templateParams: {
  customer_name: string | undefined;
  customer_email: string | undefined;
  service_name: string;
  customer_message: string;
  customer_phone: string | undefined;
}) => {
  //send email to
  send(serviceID, templateID, templateParams, publicKey).then(
    function (response) {
      console.log("SUCCESS!", response.status, response.text);
    },
    function (error) {
      console.log("FAILED...", error);
    }
  );
  send("service_ix36dyx", "template_f40ijj7", templateParams, publicKey).then(
    function (response) {
      console.log("SUCCESS!", response.status, response.text);
    },
    function (error) {
      console.log("FAILED...", error);
    }
  );
};

const ContactContent = () => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, { resetForm }) => {
        if (!values.email || !values.message || !values.name || !values.phone) {
          return;
        }
        handleClick(values);
        resetForm({ values: initialValues });
      }}
    >
      {() => (
        <Form>
          <FormContainer id="121" variant="filled">
            <StyledFormRow>
              <NameField />
            </StyledFormRow>
            <StyledFormRow>
              <NumberField />
            </StyledFormRow>
            <StyledFormRow>
              <EmailField />
            </StyledFormRow>
            <StyledFormRow>
              <MessageField />
            </StyledFormRow>
            <SubmitButton />
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};

export const ContactSection = () => {
  return <ContentSection label={"Contact"} content={<ContactContent />} />;
};
